<template>
  <div class="cooperate">
    <h2>可靠和值得信赖的合作伙伴</h2>
    <p>
      <span>
        <img src="@/assets/images/address.png" />
        地址：北京市朝阳区联合大厦1604
      </span>
      <span>
        <img src="@/assets/images/phone.png" />
        电话：18210566607,13811973070
      </span>
    </p>
    <!-- <div class="quality_cooperate-communicate">
        <a href="javascript:;">立即咨询</a>
      </div> -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.cooperate {
  height: 218px;
  background: url("../assets/images/cooperate-bg.png") no-repeat;
  background-size: cover;
  background-position: center;
  h2 {
    text-align: center;
    padding: 40px 0;
    margin: 0;
    font-size: 36px;
  }
  p {
    text-align: center;
    margin: 0;
    span:first-child {
      margin-right: 147px;
    }
  }
  &-communicate {
    text-align: center;
    margin-top: 44px;
    a {
      display: inline-block;
      width: 158px;
      height: 40px;
      line-height: 40px;
      background: rgba(255, 255, 255, 0);
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #fbb726;
      font-size: 16px;
      color: #fbb726;
    }
  }
}
</style>